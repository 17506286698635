const CONSTANTS = {
  isDesktopView: window.matchMedia('(min-width: 992px)').matches,
  isTabletView: window.matchMedia('(max-width: 768px)').matches,
  isLargeView: window.matchMedia('(max-width: 1024px)').matches,
  faq: {
    navigation: {
      id: 'faq-navigator-options-container', // navigation container id which include heading as well
      display: {
        show: 'show', // to show the navigation in mobile screen
      },
      links: {
        containerClass: 'faq-navigator-content', // navigation container class which include links only
        activeClass: 'active', // class to add to link to make then active
      },
      controls: {
        up: 'faq-navigation-up', // Up button id
        down: 'faq-navigation-down', // Down button id
        done: 'faq-select-product-done-btn', // done button id
        select: 'faq-select-product-btn', // select product button id
      },
    },
    content: {
      commonClass: 'faq__section', // content container class for each treatment section
      activeClass: 'faq__section--active', // class to add to make content active
      visibleClass: 'faq__section--visible', // class to add to make content visible (opacity: 1)
    },
    triggerSuffix: '-faq-content',
    containerPrefix: 'faq-xf-',
  },
};

const UTILITY = {
  removeHashFromString: (url: string | null): string | null => {
    if (!!url) return url.substring(1);
    return null;
  },

  extractHashFromUrl: (url: string | null): string | null => {
    if (!url) return null;

    if (url.indexOf('#') > -1) return url.split('#')[1];

    return '';
  },
};

(() => {
  // ::: FAQ START :::

  // This variable is to make UX similar to the existing live site
  const selection = {
    temporary: '', // This will store the id of the link which is temporary selected by up/down navigation
    permanent: '', // This will store the id of the link which is actually selected by user.
  };

  const getFaqNavigation = (): HTMLDivElement => {
    return document.getElementById(
      CONSTANTS.faq.navigation.id
    ) as HTMLDivElement;
  };

  /**
   * Function to be call on the page load.
   */
  const faqInit = () => {
    const nav = getFaqNavigation() as HTMLElement;
    // TO check is FAQ is in the page
    if (!nav) return;
    const xfContainer = nav?.closest('.faq-xf-container') as HTMLElement;

    const pageId = xfContainer?.id.replace(CONSTANTS.faq.containerPrefix, '');
    console.log(xfContainer, pageId, getFaqNavigation());

    // any anchor that points to the faq content
    const faqAnchors = document.querySelectorAll(
      `.${CONSTANTS.faq.navigation.links.containerClass} a[href*="${CONSTANTS.faq.triggerSuffix}"]`
    )! as NodeListOf<HTMLAnchorElement>;

    if (faqAnchors?.length) {
      faqAnchors.forEach(faqLink => {
        faqLink.addEventListener('click', e => {
          e.preventDefault();
          const href = faqLink.href;
          setActiveFaqLink(UTILITY.extractHashFromUrl(href)!);
        });
      });
    }

    // setting active tab on the page load
    const firstLinkInContainer = document.querySelector(
      `.${CONSTANTS.faq.navigation.links.containerClass} a`
    )! as HTMLAnchorElement;

    // setting the first link in the container as default tab to open
    let activeTabOnPageLoad = UTILITY.extractHashFromUrl(
      firstLinkInContainer?.href
    );

    // if one of the anchors' href matches the id of the page, then override the default to the first trigger link
    if (pageId) {
      const emphasizedLink = document.querySelector(
        `.${CONSTANTS.faq.navigation.links.containerClass} a[href="#${pageId}${CONSTANTS.faq.triggerSuffix}"]`
      ) as HTMLAnchorElement;
      if (emphasizedLink) {
        activeTabOnPageLoad = UTILITY.extractHashFromUrl(emphasizedLink.href);
      }
    }

    // initial show/hide tab
    setActiveFaqLink(activeTabOnPageLoad!, false);
  };

  /**
   * Is faq navigation is visible in the mobile view or not
   */
  const isFaqNavigationVisible = (): boolean =>
    getFaqNavigation()?.classList.contains(
      CONSTANTS.faq.navigation.display.show
    );

  /**
   * This function will return list of faq navigation id
   */
  const getFaqNavigationList = (): (string | null)[] => {
    const nodeLinkList = document.querySelectorAll(
      `#${CONSTANTS.faq.navigation.id} a`
    );
    return Array.from(nodeLinkList, link => {
      return UTILITY.removeHashFromString(link.getAttribute('href'));
    });
  };

  /**
   * Trigger the relevant action on faq button click
   * @param id : id of the button clicked
   */
  const faqButtonClickListener = (id: string) => {
    const getNextOrPreviousLink = (index: -1 | 1): string => {
      const linkArray: (string | null)[] = getFaqNavigationList();

      const currentSelectedIndex = linkArray.indexOf(selection.temporary);

      if (currentSelectedIndex === 0 && index === -1)
        return linkArray[linkArray.length - 1]!;

      if (currentSelectedIndex === linkArray.length - 1 && index === 1)
        return linkArray[0]!;

      return linkArray[currentSelectedIndex + index]!;
    };

    /**
     * This function to scroll navigation dropdown to the highlighted link in mobile view
     * @param linkHrefLink Navigation link to which focus should be set Ex. faq-collection
     */
    const setFocusToLink = (linkHrefLink: string) => {
      if (CONSTANTS.isDesktopView) return;

      const link = document.querySelector(
        `a[href='#${linkHrefLink}']`
      )! as HTMLAnchorElement;

      const parent = link?.parentNode as HTMLElement;

      const scrollIntoViewOption: ScrollIntoViewOptions = {
        behavior: 'smooth',
        block: 'center',
      };

      if (parent) {
        parent?.scrollIntoView?.(scrollIntoViewOption);
      } else {
        link?.scrollIntoView?.(scrollIntoViewOption);
      }
    };

    switch (id) {
      // When select option button click in mobile view
      case CONSTANTS.faq.navigation.controls.select:
        setFaqNavigationVisibility(isFaqNavigationVisible() ? 'HIDE' : 'SHOW');
        break;

      // When done button click in mobile view
      case CONSTANTS.faq.navigation.controls.done:
        setFaqNavigationVisibility('HIDE');
        setActiveFaqLink(selection.temporary);
        break;

      case CONSTANTS.faq.navigation.controls.up:
        const previousLinkId = getNextOrPreviousLink(-1);
        highlightSelectedFaqLink(previousLinkId, false);
        setFocusToLink(previousLinkId);
        break;

      case CONSTANTS.faq.navigation.controls.down:
        const nextLinkId = getNextOrPreviousLink(1);
        highlightSelectedFaqLink(nextLinkId, false);
        setFocusToLink(nextLinkId);
        break;
    }
  };

  const faqWindowClickHandler = e => {
    if (!isFaqNavigationVisible()) return;

    const targetClicked = e.target! as HTMLElement;

    const selectBtn = document.getElementById(
      CONSTANTS.faq.navigation.controls.select
    )! as HTMLElement;

    // Checking if user clicked on select product button or inside navigation container
    if (
      getFaqNavigation().contains(targetClicked) ||
      selectBtn.contains(targetClicked)
    )
      return;

    // When user clicked outside hide the navigation (mobile view) and reset the highlight to original state(mobile view)
    setFaqNavigationVisibility('HIDE');
  };

  /**
   * Function to show or hide the faq option selection in the mobile view
   */
  const setFaqNavigationVisibility = (visibility: 'SHOW' | 'HIDE') => {
    if (visibility === 'SHOW') {
      getFaqNavigation().classList.add(CONSTANTS.faq.navigation.display.show);

      // Listening to click event to close the dropdown menu in mobile view
      window.removeEventListener('click', faqWindowClickHandler);
      window.addEventListener('click', faqWindowClickHandler);
    } else {
      getFaqNavigation().classList.remove(
        CONSTANTS.faq.navigation.display.show
      );
      window.removeEventListener('click', faqWindowClickHandler);
    }
  };

  /**
   * Function to highlight text in the faq navigation
   * @param selectedOptionId id of tab to make active Ex. faq-collections
   * @param changeSelectButtonText to change select button text or not
   */
  const highlightSelectedFaqLink = (
    selectedOptionId: string,
    changeSelectButtonText: boolean
  ) => {
    // remove active class from all option links
    document
      .querySelectorAll(`.${CONSTANTS.faq.navigation.links.containerClass} a`)
      .forEach(el => {
        el.classList.remove(CONSTANTS.faq.navigation.links.activeClass);
        let closestHeading = el.closest('h4');
        if (!closestHeading) {
          closestHeading = el.closest('h5');
        }

        if (closestHeading) {
          closestHeading.classList.remove(
            CONSTANTS.faq.navigation.links.activeClass
          );
        }
      });

    // highlight selected option and put the text in select product button text
    const selectedLink = document.querySelector(
      "a[href='#" + selectedOptionId + "']"
    );
    if (!!selectedLink) {
      selectedLink.classList.add(CONSTANTS.faq.navigation.links.activeClass);
      if (changeSelectButtonText) {
        const selectBtnText = document.querySelector(
          `#${CONSTANTS.faq.navigation.controls.select} .cmp-button__text`
        )! as HTMLSpanElement;
        selectBtnText.innerHTML = selectedLink.innerHTML;
      }

      let closestHeading = selectedLink.closest('h4');
      if (!closestHeading) {
        closestHeading = selectedLink.closest('h5');
      }

      if (closestHeading) {
        closestHeading.classList.add(
          CONSTANTS.faq.navigation.links.activeClass
        );
      }
    }

    selection.temporary = selectedOptionId;
  };

  /**
   * Set active faq tab
   * @param selectedOptionId id of tab to make active Ex. faq-collections
   * @param changeSelectButtonText Pass true to change the select product btn text to selected product text, else false
   */
  const setActiveFaqLink = (
    selectedOptionId: string,
    changeSelectButtonText: boolean = true
  ) => {
    // Displaying FAQ content container for a particular section
    let selectedTabDOM = document.getElementById(
      `${selectedOptionId}`
    ) as HTMLDivElement;

    if (!selectedTabDOM) {
      selectedTabDOM = document.querySelector(
        `.${CONSTANTS.faq.content.commonClass}`
      ) as HTMLDivElement;
    }

    // only if the content is not already active, make the content active
    if (
      !selectedTabDOM?.classList?.contains?.(CONSTANTS.faq.content.activeClass)
    ) {
      highlightSelectedFaqLink(selectedOptionId, changeSelectButtonText);
      document
        .querySelector(`.${CONSTANTS.faq.content.activeClass}`)
        ?.classList?.remove?.(
          `${CONSTANTS.faq.content.activeClass}`,
          `${CONSTANTS.faq.content.visibleClass}`
        );
      selectedTabDOM?.classList?.add?.(CONSTANTS.faq.content.activeClass);

      // activating accordion
      const accordionDom = selectedTabDOM?.querySelector?.(
        "[data-component='accordion']"
      ) as HTMLElement;
      const badgerInstance = window._badgerInstances?.[accordionDom?.id];

      badgerInstance?.calculateAllPanelsHeight?.();

      selection.permanent = selection.temporary = selectedOptionId;

      // making sure that accordion animation gets completed and then the content fades in
      const timer = setTimeout(() => {
        selectedTabDOM?.classList?.add?.(CONSTANTS.faq.content.visibleClass);
        clearTimeout(timer);
      }, 300);
    }
  };

  /*
   * Select and make a question active in FAQ section
   * given the id matches [content-id]___[question-id] e.g. chin-faq-content___item_4
   */
  const selectFAQ = (faqNavId: string) => {
    if (faqNavId) {
      const faqArr = faqNavId.split('___') || [];
      const selectedOptionId = faqArr[0];
      const selectedItem = faqArr[1];

      if (selectedOptionId && selectedItem) {
        // select specified faq item
        const faqAccordionElementQuery = `#${selectedOptionId} dl dt[data-name="${selectedItem}"]`;
        const faqAccordionTitle = document.querySelector(
          `${faqAccordionElementQuery} p`
        );
        const faqAccordionToggleElement = document.querySelector(
          `${faqAccordionElementQuery} .aaaem-accordion__icon`
        ) as HTMLButtonElement;

        if (faqAccordionTitle) {
          // select specified section
          setActiveFaqLink(selectedOptionId);
          if (!faqAccordionTitle?.classList?.contains('-ba-is-active')) {
            faqAccordionToggleElement?.click();
          }
          const selectedFAQTitle = document.querySelector(
            `${faqAccordionElementQuery}`
          );
          if (selectedFAQTitle) {
            const scrollIntoViewOption: ScrollIntoViewOptions = {
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            };
            setTimeout(() => {
              selectedFAQTitle?.scrollIntoView?.(scrollIntoViewOption);
            }, 400);
          }
        }
      }
    }
  };
  // ::: FAQ END :::

  // on resize and on expand of accordion, making sure that the height of the accordion content is accurate
  const handleAccordion = () => {
    const accordions = document.querySelectorAll('.js-badger-accordion');
    if (accordions?.length) {
      const badgerInstances = window._badgerInstances;
      const badgerKeys = badgerInstances && Object.keys(badgerInstances);
      if (badgerKeys?.length) {
        // making sure the height of the accordion content is accurate on load.
        window.addEventListener('load', () => {
          badgerKeys.forEach(key => {
            window._badgerInstances[key].calculateAllPanelsHeight?.();
          });
        });

        // making sure the height of the accordion content is accurate on resize.
        window.addEventListener('resize', () => {
          badgerKeys.forEach(key => {
            window._badgerInstances[key].calculateAllPanelsHeight?.();
          });
        });
      }
    }
  };

  const init = () => {
    window.addEventListener('resize', () => {
      CONSTANTS.isDesktopView = window.matchMedia('(min-width: 992px)').matches;
      CONSTANTS.isTabletView = window.matchMedia('(max-width: 768px)').matches;
      CONSTANTS.isLargeView = window.matchMedia('(max-width: 1024px)').matches;
    });

    // timer to let the DOM settle
    setTimeout(() => {
      faqInit();
      handleAccordion();
    }, 50);

    if (window.Bus) {
      // TO check is FAQ is in the page
      if (getFaqNavigation()) {
        // to listen to events here
        window.Bus.on('emu-button:click', ({ id }) => {
          faqButtonClickListener(id);
          selectFAQ(id);
        });
      }
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
