// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
import './components/**/*.ts';
import './resources/images/favicon.png';
interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean;
  env: string;
  browser: {
    name: string;
    version: string;
  };
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  };
}

declare global {
  interface Window {
    AAAEM: AAAEM;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; // carousel
    _tabbyInstances: any; // tabs
    _plyrInstances: any; // vimeo embed
    _badgerInstances: any; // accordion
  }
}

(() => {
  // when close menu button is clicked, clicking the open, so that the menu will be toggled
  const handleMobileMenuClose = () => {
    const menuTrigger = document.querySelector(
      '#nav-mobile-trigger'
    ) as HTMLButtonElement;
    const isiLinksInHeader = document.querySelectorAll(
      'header.header a[href="#isi"]'
    ) as NodeListOf<HTMLAnchorElement>;

    window.Bus?.on('emu-button:click', ({ id }) => {
      if (id === 'nav-mobile-trigger-close') {
        menuTrigger?.click();
      } else if (id === 'nav-mobile-trigger') {
        document.body.classList.toggle('u-mobile-hide-overflow');
      }
    });

    // if menu is in open state, close if, if ISI links are clicked
    isiLinksInHeader.forEach($el => {
      $el.addEventListener('click', () => {
        if (document.body.classList.contains('u-mobile-hide-overflow')) {
          menuTrigger?.click();
        }
      });
    });
  };

  const init = () => {
    handleMobileMenuClose();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
